<template>
  <div
    :style="{
      minHeight: minheight,
      position: 'relative',
    }"
    class="loginwrapbox"
  >
    <div class="loginbox">
      <h3 style="padding-top: 40px; width: 300px; margin: 0 auto">
        农科天地电商后台管理系统
      </h3>
      <el-form
        ref="LoginForm"
        class="loginform"
        style="margin-top: 30px"
        :model="loginForm"
      >
        <el-form-item>
          <el-input
            v-model="loginForm.loginId"
            clearable
            placeholder="请输入账户（用户名）"
          >
            <template #prefix>
              <i class="el-input__icon el-icon-user"></i>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="loginForm.loginPwd"
            clearable
            prefix-icon="el-icon-lock "
            placeholder="请输入密码"
            :type="isPassword ? 'password' : 'text'"
          >
            <template #suffix>
              <i class="el-input__icon el-icon-view" @click="changeType"></i>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item class="vailCodebox">
          <el-input
            v-model="loginForm.captcha"
            clearable
            style="width: 180px"
            placeholder="请输入验证码"
          >
            <template #prefix>
              <i class="el-input__icon el-icon-message"></i>
            </template>
          </el-input>
          <div
            style="margin-left: 20px; height: 40px; cursor: pointer"
            @click="getvailCode"
          >
            <img :src="vailcode" alt="" style="height: 100%; width: 100%" />
          </div>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        style="width: 300px; margin-bottom: 40px"
        @click="storageToken"
        >登陆</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loginForm: {
        loginId: '',
        loginPwd: '',
        captcha: ''
      },
      rules: {
        userName: [
          { required: true, message: '请输入用户名！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        vailCode: [
          { required: true, message: '请输入验证码！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ]
      },
      isPassword: true,
      BaseUrl: '',
      vailcode: '',
      minheight: ''
    }
  },
  created() {
    this.getvailCode()
    // console.log(window.innerHeight)
    this.BaseUrl = this.$http.defaults.baseURL
    this.minheight = window.innerHeight + 'px'
  },
  methods: {
    async getvailCode() {
      const res = await this.$http.get('/nktdec/login/kaptcha.jpg')
      // console.log(res.data)
      this.vailcode = res.data
    },
    changeType() {
      this.isPassword = !this.isPassword
    },
    async storageToken() {
      // console.log(this.loginForm)
      const res = await this.$http.post('/nktdec/login/adminlogin', this.loginForm)
      // console.log(res)
      if (res.data.code !== '1') {
        this.$message.error(res.data.message)
        this.getvailCode()
        return
      }
      window.sessionStorage.setItem('authToken', res.data.result.loginToken)
      this.$store.commit('setToken', res.data.result.loginToken)
      window.sessionStorage.setItem('userInfo', JSON.stringify({
        userType: res.data.result.userType,
        userName: res.data.result.userName,
        userTypeVal: res.data.result.userTypeVal,
        userId: res.data.result.userId
      }))
      this.$store.commit('setuserInfo', {
        userType: res.data.result.userType,
        userName: res.data.result.userName,
        userTypeVal: res.data.result.userTypeVal,
        userId: res.data.result.userId
      })
      this.$router.push('/home')
    }
  }
}
</script>
<style scoped>
.loginform {
  margin: 0 auto;
  width: 300px;
}
.loginwrapbox {
  background-image: url("../../assets/img/login.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.loginbox {
  width: 380px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>
